import { useMemo } from 'react';

import { Booking } from '@/types';

import { useAppContext } from './index';

export const useDateRent = () => {
  const { state, dispatch } = useAppContext();

  const getState = () => ({ ...state.booking, isStoreLoaded: state.isStoreLoaded });

  const setState = (payload: Booking) =>
    dispatch({
      type: 'UPDATE_BOOKING',
      payload,
    });

  return useMemo(() => ({ getRentData: getState, setRentData: setState }), [getState, setState]);
};
