import { useQuery } from 'react-query';
import { AxiosResponse } from 'axios';

import { api } from '@/api';
import { ResponseUser } from '@/api/user';
import { QueryKey } from '@/constants/api';
import { Document } from '@/types';

export const useGetUser = () => useQuery<AxiosResponse<ResponseUser>, Error>(QueryKey.USER, api.user.getCurrentUser);

export const useGetUserDocuments = () => useQuery<AxiosResponse<Document[]>, Error>(QueryKey.DOCUMENTS, api.user.getDocuments, {
    refetchOnMount: false,
    refetchOnReconnect: false,
    refetchOnWindowFocus: false,
  });
