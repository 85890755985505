/* eslint-disable max-len */
export const CarsCatalogLoading = () => (
  <div className="animate-pulse">
    <div className="h-6 ml-0 bg-gray-300 rounded-lg dark:bg-gray-700 max-w-[540px] mb-9" />
    <div className="relative mb-9 lg:mb-5 flex flex-col md:flex-row md:items-end font-medium gap-4 md:gap-8">
      <div className="rounded-lg h-96 flex font-medium text-sm bg-gray-300 dark:bg-gray-700 justify-between md:w-2/3 mb-5" />
      <div className="rounded-lg h-96 flex font-medium text-sm bg-gray-300 dark:bg-gray-700 justify-between md:w-2/3 mb-5" />
      <div className="rounded-lg h-96 flex font-medium text-sm bg-gray-300 dark:bg-gray-700 justify-between md:w-2/3 mb-5" />
      <div className="rounded-lg h-96 flex font-medium text-sm bg-gray-300 dark:bg-gray-700 justify-between md:w-2/3 mb-5" />
    </div>
    <div className="hidden lg:flex relative mb-9 lg:mb-5 flex flex-col md:flex-row md:items-end font-medium gap-4 md:gap-8">
      <div className="rounded-lg h-40 md:h-96 flex font-medium text-sm bg-gray-300 dark:bg-gray-700 justify-between md:w-2/3 mb-5" />
      <div className="rounded-lg h-40 md:h-96 flex font-medium text-sm bg-gray-300 dark:bg-gray-700 justify-between md:w-2/3 mb-5" />
      <div className="rounded-lg h-40 md:h-96 flex font-medium text-sm bg-gray-300 dark:bg-gray-700 justify-between md:w-2/3 mb-5" />
      <div className="rounded-lg h-40 md:h-96 flex font-medium text-sm bg-gray-300 dark:bg-gray-700 justify-between md:w-2/3 mb-5" />
    </div>
  </div>
);
