import { FC, memo, ReactNode, useEffect, useRef, useState } from 'react';
import { Loader } from '@googlemaps/js-api-loader';

import { GOOGLE_API_KEY } from '@/constants/global';
import { GoogleMapApi, GoogleMapsContext } from '@/context/googleContext';
import { GoogleMapLoadStatus } from '@/types';

type Props = {
  children: ReactNode;
};

export const GoogleMapWrapper: FC<Props> = memo(({ children }) => {
  const [status, setStatus] = useState<GoogleMapLoadStatus>(GoogleMapLoadStatus.UNDEFINED);
  const googleMapApi = useRef<GoogleMapApi | null>(null);

  useEffect(() => {
    const loader = new Loader({
      apiKey: GOOGLE_API_KEY,
      version: 'weekly',
      libraries: ['places'],
    });
    setStatus(GoogleMapLoadStatus.IN_PROGRESS);

    loader
      .load()
      .then((result) => {
        setStatus(GoogleMapLoadStatus.LOADED);
        googleMapApi.current = result.maps;
      })
      .catch((e) => {
        setStatus(GoogleMapLoadStatus.FAILED);
        console.error('GoogleMapService: loading api has failed ', e);
      });
  }, []);

  return (
    <GoogleMapsContext.Provider value={{ service: googleMapApi.current, status }}>
      {children}
    </GoogleMapsContext.Provider>
  );
});
