import { useTranslation } from 'next-i18next';

export const ChosenCarLabel = () => {
  const { t } = useTranslation();

  return (
    // eslint-disable-next-line max-len
    <div className="flex items-center w-fit gap-2 py-2 px-3 border border-[#DEECFF] rounded-md bg-blue-100 bg-opacity-20">
      <svg fill="none" height="12" stroke="#1ABD8C" viewBox="0 0 17 12" width="17" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 4.83887L6.3033 10.1422L15.1421 1.30333" stroke="#257AEA" strokeLinecap="round" strokeWidth="2" />
      </svg>

      <span className="font-medium">{t('chosenCar')}</span>
    </div>
  );
};
