import { useMemo } from 'react';
import { useInfiniteQuery } from 'react-query';

import { api } from '@/api';
import { QueryKey } from '@/constants/api';
import { DEFAULT_LIMIT } from '@/constants/global';
import { BookingItemType, LocaleType, RentalResponse } from '@/types';

const apiFunction = {
  [BookingItemType.Current]: api.booking.getCurrentBooking,
  [BookingItemType.Future]: api.booking.getFutureBooking,
  [BookingItemType.Past]: api.booking.getPastBooking,
};

const queryName = {
  [BookingItemType.Current]: QueryKey.CURRENT_BOOKINGS,
  [BookingItemType.Future]: QueryKey.FUTURE_BOOKINGS,
  [BookingItemType.Past]: QueryKey.PAST_BOOKINGS,
};

export const useGetBookings = ({ locale, type }: { locale: LocaleType; type: BookingItemType }) => {
  const {
    fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    data: bookingData,
    refetch,
  } = useInfiniteQuery<RentalResponse, Error>(
    [queryName[type], locale],
    ({ pageParam = 0 }) => apiFunction[type]({ offset: pageParam * DEFAULT_LIMIT, limit: DEFAULT_LIMIT, locale }),
    {
      getNextPageParam: ({ total, offset, limit }, pages) => (total > offset + limit ? pages.length : false),
    }
  );

  const concatedPages = bookingData || {
    pages: [
      {
        data: [],
        offset: 0,
        limit: 0,
        total: 0,
      } as RentalResponse,
    ],
  };

  const { offset, total, limit } = concatedPages.pages.at(-1)!;

  const bookings = useMemo(() => concatedPages.pages.flatMap((page) => page.data), [concatedPages]);

  return {
    bookings,
    loadMore: fetchNextPage,
    hasNextPage,
    isLoading,
    isError,
    total,
    offset: offset + limit,
    refreshBooking: refetch,
  };
};
