import { FC, useMemo, useState } from 'react';
import { useBoolean } from 'react-use-boolean';
import { useTranslation } from 'next-i18next';
import cn from 'classnames';

import { useDateRent } from '@/context/useDateRent';
import { CarCard } from '@/features/CarCard';
import { BodyType, Car, CarFiltersPrepared } from '@/types';

import { ColorsLayout } from './ColorsLayout';
import { TypesLayout } from './TypesLayout';

type Props = {
  cars: Car[];
  filters: CarFiltersPrepared;
  onSelectCar?: (car: Car) => void;
};

export const CarsCatalogData: FC<Props> = ({ onSelectCar, cars, filters: { bodyTypes, colors } }) => {
  const { getRentData, setRentData } = useDateRent();
  const { car: selectedCar, color, bodyType } = getRentData();
  const { t } = useTranslation();
  const [selectedType, setType] = useState<BodyType | null>(bodyType!);
  const [selectedColor, setColor] = useState<string | null>(color!);

  const carId = selectedCar?.id;

  const handleSetColor = (newColor: string | null) => {
    setRentData({ color: newColor });
    setColor(newColor);
  };

  const handleSetType = (newType: BodyType | null) => {
    setRentData({ bodyType: newType });
    setType(newType);
  };

  const [sortPrice, { toggle: sortByPrice }] = useBoolean(false);

  const handleSelectCar = (car: Car) => {
    setRentData({
      car,
    });

    if (onSelectCar) {
      onSelectCar(car);
    }
  };

  const filteredCars = useMemo(() => {
    if (selectedColor && selectedType) {
      return cars.filter(
        (carItem) =>
          carItem.color.toUpperCase() === selectedColor.toUpperCase() &&
          carItem.model.body_type.toUpperCase() === selectedType.toUpperCase()
      );
    }

    if (selectedColor) {
      return cars.filter((carItem) => carItem.color.toUpperCase() === selectedColor.toUpperCase());
    }

    if (selectedType) {
      return cars.filter((carItem) => carItem.model.body_type.toUpperCase() === selectedType.toUpperCase());
    }

    return cars;
  }, [selectedColor, selectedType, cars]);

  const carsForView = sortPrice
    ? filteredCars.sort((a: Car, b: Car) => b.min_price - a.min_price)
    : filteredCars.sort((a: Car, b: Car) => a.min_price - b.min_price);

  return (
    <div>
      <div className="mb-9 lg:mb-12 flex flex-col md:flex-row md:items-end font-medium gap-4 md:gap-8">
        <div className="flex flex-col lg:flex-row">
          <span className="text-gray-600 mb-2 sm:mb-0 mr-3">{t('filterCars')}:</span>
          <div className="flex gap-4">
            <span
              className={cn('cursor-pointer', {
                'primary-color-text': !selectedType,
                'transition hover:opacity-70': !!selectedType,
              })}
              onClick={() => handleSetType(null)}>
              {t('all')}
            </span>
            <TypesLayout bodyType={bodyTypes} currentType={selectedType} setType={handleSetType} />
          </div>
        </div>
        <div className="flex items-center gap-3">
          <span className="text-gray-600">{t('color')}:</span>
          <span
            className={cn('cursor-pointer', {
              'primary-color-text': !selectedColor,
              'transition hover:opacity-70': !!selectedColor,
            })}
            onClick={() => handleSetColor(null)}>
            {t('all')}
          </span>
          <ColorsLayout colors={colors} currentColor={selectedColor} setColor={handleSetColor} />
        </div>
        <div className="flex items-center cursor-pointer" onClick={sortByPrice}>
          <span className="text-gray-600 mr-2 transition hover:opacity-80">{t('price')}</span>
          <svg
            className={cn({
              'rotate-180 transition': !!sortPrice,
              transition: !sortPrice,
            })}
            fill="none"
            height="15"
            viewBox="0 0 13 15"
            width="13">
            <path d="M1 9L6.5 14L12 9" stroke="#257AEA" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" />
            <path d="M6.5 1.5V12.5" stroke="#257AEA" strokeLinecap="round" strokeWidth="2" />
          </svg>
        </div>
      </div>

      {carsForView.length ? (
        <div className="grid grid-cols-2 lg:grid-cols-4 gap-x-6 lg:gap-x-12 gap-y-8 lg:gap-y-20">
          {carsForView.map((car) => (
            <CarCard car={car} isSelected={!!carId && car.id === carId} key={car.id} onSelectCar={handleSelectCar} />
          ))}
        </div>
      ) : (
        <div className="mt-6 w-full p-8 bg-blue-50 border border-blue-100 w-fit rounded-2xl -mt-10">
          <p className="font-medium block text-center primary-color-text">{t('notAvailable')} :(</p>
        </div>
      )}
    </div>
  );
};
