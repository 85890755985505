import { createContext } from 'react';

import { GoogleMapLoadStatus } from '@/types';

export type GoogleMapApi = typeof google.maps;

export type GoogleMapsContextType = {
  service: GoogleMapApi | null;
  status: GoogleMapLoadStatus;
};

export const GoogleMapsContext = createContext<GoogleMapsContextType>({
  service: null,
  status: GoogleMapLoadStatus.UNDEFINED,
});
