import { FC } from 'react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import { ChosenCarLabel } from 'components/ChosenCarLabel';

import { Route } from '@/constants/routes';
import { Car } from '@/types';

type Props = {
  isSelected?: boolean;
  car: Car;
  onSelect: (car: Car) => void;
};

export const CarCardButton: FC<Props> = ({ isSelected, car, onSelect }) => {
  const router = useRouter();
  const { t } = useTranslation();

  if (router.route === Route.Home) {
    return (
      <Link
        className="text-white primary-color-bg py-3 px-6 md:px-12 block w-fit cursor-pointer
        rounded-xl mt-4 md:mt-8 text-base md:text-lg font-bold transition hover:-translate-y-1"
        href={`${Route.Car}/${car.slug}`}
        prefetch={false}
        role="button">
        {t('details')}
      </Link>
    );
  }

  if (isSelected) {
    return (
      <div className="py-1 mt-4 md:mt-8">
        <ChosenCarLabel />
      </div>
    );
  }

  return (
    <button
      className="text-white primary-color-bg py-3 px-6 md:px-12 block w-fit cursor-pointer
      rounded-xl mt-4 md:mt-8 text-base md:text-lg font-bold transition hover:-translate-y-1"
      onClick={() => onSelect(car)}
      type="button">
      {t('selectThisCar')}
    </button>
  );
};
