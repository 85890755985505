import { FC } from 'react';

import { ErrorWidget } from '@/components/ErrorWidget';
import { Car, LocaleType } from '@/types';
import { useGetCarFilters } from '@/utils/api';

import { CarsCatalogData } from './CarsCatalogData';
import { CarsCatalogLoading } from './CarsCatalogLoading';

type Props = {
  isLoading: boolean;
  isError: boolean;
  cars: Car[];
  refetch: VoidFunction;
  locale: LocaleType;
  onSelectCar?: (selectedCar: Car) => void;
};

export const CarsCatalog: FC<Props> = ({ cars, isLoading, isError, refetch, onSelectCar }) => {
  const {
    isLoading: isCarFiltersLoading,
    isError: isCarFilterError,
    data: filterData,
    refetch: filterRefetch,
  } = useGetCarFilters();

  const preparedFilters = { bodyTypes: filterData?.body_type || [], colors: filterData?.color || [] };

  if (isLoading || isCarFiltersLoading) {
    return <CarsCatalogLoading />;
  }

  if (isError || isCarFilterError) {
    return (
      <ErrorWidget
        refetch={() => {
          refetch();
          filterRefetch();
        }}
      />
    );
  }

  return <CarsCatalogData cars={cars} filters={preparedFilters} onSelectCar={onSelectCar} />;
};
