import { useTranslation } from 'next-i18next';
import dayjs from 'dayjs';

import { Booking, RentalState } from '@/types';

export const useValidation = () => {
  const { t } = useTranslation();

  const validateDateAndAddress = (data: Booking & RentalState, isRentalCurrentValidation?: boolean) => {
    const result = [];
    if (!data.addressToDeliverGeo?.info?.description && !data.addressToDeliver) {
      result.push({ id: 'addressToDeliverGeo', message: t('errors.noAddressToDeliver') });
    }

    if (!data.addressToPickupGeo?.info?.description && !data.addressToPickup) {
      result.push({ id: 'addressToPickupGeo', message: t('errors.noAddressToDrop') });
    }

    if (!data.startDate) {
      result.push({ id: 'startDate', message: t('errors.noStartDate') });
    }

    if (!data.endDate) {
      result.push({ id: 'endDate', message: t('errors.noEndDate') });
    }

    if (data.startDate && data.endDate) {
      const startDay = dayjs(`${data.startDate} ${data.startTime}`);
      const endDay = dayjs(`${data.endDate} ${data.endTime}`);

      if (endDay.isBefore(startDay.add(1, 'day'))) {
        result.push({ id: 'endDateRange', message: t('errors.endDate') });
      }

      if (startDay.isBefore(dayjs().add(1, 'day')) && !isRentalCurrentValidation) {
        result.push({ id: 'startDateRange', message: t('errors.startDate') });
      }
    }
    return result;
  };

  return {
    validateDateAndAddress,
  };
};
