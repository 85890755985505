import { FC } from 'react';
import Image from 'next/image';
import { useTranslation } from 'next-i18next';

import { CarCardButton } from '@/features/CarCard/CarCardButton';
import { Car } from '@/types';

const SaleFigureIcon = require('assets/icons/sale-figure-icon.svg');

type Props = {
  isSelected?: boolean;
  onSelectCar: (car: Car) => void;
  car: Car;
};

export const CarCard: FC<Props> = ({ onSelectCar, isSelected, car }) => {
  const { t } = useTranslation();

  const transmissionType =
    car.model.transmission_type === 'auto' ? t('car.transmissionType.automatic') : t('car.transmissionType.manual');

  return (
    <div>
      <div className="flex font-medium text-sm justify-between md:w-2/3 mb-5">
        <span className="primary-color-text capitalize">{car.fuel_type}</span>
        <span className="capitalize">{transmissionType}</span>
        <span className="flex items-center">
          <svg
            className="mr-1"
            fill="none"
            height="12"
            viewBox="0 0 10 12"
            width="10"
            xmlns="http://www.w3.org/2000/svg">
            <circle cx="5" cy="3" fill="#257AEA" r="3" />
            <path
              d="M10 12C10 12 7.76142 12 5 12C2.23858 12 0 12 0 12C0 9.23858 2.23858 7
              5 7C7.76142 7 10 9.23858 10 12Z"
              fill="#257AEA"
            />
          </svg>
          {car.doors_count}
        </span>
      </div>
      <div className="relative">
        {car.is_promotion ? (
          <Image alt={t('promotion')} className="absolute top-0 left-0" src={SaleFigureIcon} />
        ) : null}
        <img alt={car.model.model_name} src={car.model.image_url} />
      </div>
      <p className="mt-6 mb-3 text-base md:text-lg font-medium">
        {car.model.brand_name} {car.model.model_name}
      </p>
      <div className="h-0.5 w-1/3 bg-gray-400" />
      <div className="flex items-center mt-3">
        <span className="text-sm md:text-base text-blue-500 mr-4 font-medium">
          {t('from')} <span className="text-base md:text-lg">${car.min_price}</span>/{t('day')}
        </span>
        {car.price_without_discount ? (
          <span
            className="relative px-1 text-gray-600 before:border-b before:absolute
            before:border-red-500 before:w-full before:-left-[4%] before:h-[12px] before:-rotate-[16deg]"
            style={{ content: '' }}>
            ${car.price_without_discount}
          </span>
        ) : null}
      </div>
      <p className="font-light mt-1 text-sm md:text-base">{car.model.description}</p>
      <CarCardButton car={car} isSelected={isSelected} onSelect={onSelectCar} />
    </div>
  );
};
